import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a7d91718 = () => interopDefault(import('../pages/pac/index.vue' /* webpackChunkName: "pages/pac/index" */))
const _750310e6 = () => interopDefault(import('../pages/pac/manual-subscription.vue' /* webpackChunkName: "pages/pac/manual-subscription" */))
const _010be34a = () => interopDefault(import('../pages/pac/operations-to-subscribe.vue' /* webpackChunkName: "pages/pac/operations-to-subscribe" */))
const _cf5f9eb6 = () => interopDefault(import('../pages/pac/success.vue' /* webpackChunkName: "pages/pac/success" */))
const _e0d150de = () => interopDefault(import('../pages/payment/down-payments/index.vue' /* webpackChunkName: "pages/payment/down-payments/index" */))
const _06a2d79e = () => interopDefault(import('../pages/payment/error.vue' /* webpackChunkName: "pages/payment/error" */))
const _31347e77 = () => interopDefault(import('../pages/payment/installments/index.vue' /* webpackChunkName: "pages/payment/installments/index" */))
const _4435a43e = () => interopDefault(import('../pages/payment/preorders/index.vue' /* webpackChunkName: "pages/payment/preorders/index" */))
const _c1c8f5e2 = () => interopDefault(import('../pages/payment/select-payment.vue' /* webpackChunkName: "pages/payment/select-payment" */))
const _21dc0899 = () => interopDefault(import('../pages/payment/success.vue' /* webpackChunkName: "pages/payment/success" */))
const _09d84dca = () => interopDefault(import('../pages/result/error/index.vue' /* webpackChunkName: "pages/result/error/index" */))
const _85460fb4 = () => interopDefault(import('../pages/result/not-supported-debts.vue' /* webpackChunkName: "pages/result/not-supported-debts" */))
const _59e9ba0c = () => interopDefault(import('../pages/result/without-debts.vue' /* webpackChunkName: "pages/result/without-debts" */))
const _5b8a5289 = () => interopDefault(import('../pages/payment/installments/list.vue' /* webpackChunkName: "pages/payment/installments/list" */))
const _613d3f34 = () => interopDefault(import('../pages/payment/installments/partial.vue' /* webpackChunkName: "pages/payment/installments/partial" */))
const _989de442 = () => interopDefault(import('../pages/payment/preorders/list.vue' /* webpackChunkName: "pages/payment/preorders/list" */))
const _12a3dd50 = () => interopDefault(import('../pages/payment/preorders/partial.vue' /* webpackChunkName: "pages/payment/preorders/partial" */))
const _525a6d9e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/pac",
    component: _a7d91718,
    name: "pac"
  }, {
    path: "/pac/manual-subscription",
    component: _750310e6,
    name: "pac-manual-subscription"
  }, {
    path: "/pac/operations-to-subscribe",
    component: _010be34a,
    name: "pac-operations-to-subscribe"
  }, {
    path: "/pac/success",
    component: _cf5f9eb6,
    name: "pac-success"
  }, {
    path: "/payment/down-payments",
    component: _e0d150de,
    name: "payment-down-payments"
  }, {
    path: "/payment/error",
    component: _06a2d79e,
    name: "payment-error"
  }, {
    path: "/payment/installments",
    component: _31347e77,
    name: "payment-installments"
  }, {
    path: "/payment/preorders",
    component: _4435a43e,
    name: "payment-preorders"
  }, {
    path: "/payment/select-payment",
    component: _c1c8f5e2,
    name: "payment-select-payment"
  }, {
    path: "/payment/success",
    component: _21dc0899,
    name: "payment-success"
  }, {
    path: "/result/error",
    component: _09d84dca,
    name: "result-error"
  }, {
    path: "/result/not-supported-debts",
    component: _85460fb4,
    name: "result-not-supported-debts"
  }, {
    path: "/result/without-debts",
    component: _59e9ba0c,
    name: "result-without-debts"
  }, {
    path: "/payment/installments/list",
    component: _5b8a5289,
    name: "payment-installments-list"
  }, {
    path: "/payment/installments/partial",
    component: _613d3f34,
    name: "payment-installments-partial"
  }, {
    path: "/payment/preorders/list",
    component: _989de442,
    name: "payment-preorders-list"
  }, {
    path: "/payment/preorders/partial",
    component: _12a3dd50,
    name: "payment-preorders-partial"
  }, {
    path: "/",
    component: _525a6d9e,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
